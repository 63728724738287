import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { BaseLayout } from '../../components/BaseLayout';
import { Loader } from '../../components/Loader/LoaderBooking';
import { siteNavigate } from '../../services/nav'

import {
    bookAllProducts,
    handOffBooking,
    setupPaymentPlan
} from '../../hooks/useRequest';

export function BookingPage(){

    const {
        RootStore: {
            configuration,
            userToken
        },
    } = useStores() as { RootStore: RootStore };

    const [bookingCompleted, setBookedProcessCompleted] = useState<boolean>(false);

    const [searchParams] = useSearchParams();

    const fNav = useNavigate();


    async function setUpDirectDebit(_bookingId: number, sessionId: string) {

        const ddDetail = sessionStorage.getItem('ddDetail');
        if(ddDetail == null){
          return
        }
    
        const ddDetailObj = JSON.parse(ddDetail)
    
        try {
          await setupPaymentPlan(
            userToken,
            sessionId,
            _bookingId,
            ddDetailObj.planID,
            ddDetailObj.accountHolder,
            '',
            ddDetailObj.accountNumber,
            ddDetailObj.sortCode,
            ddDetailObj.bankName,
            ddDetailObj.bankAddress,
            ddDetailObj.bankPostCode,
          );
        } catch (error) {
          console.log('Unable to set up direct debit', error);
        }
      }

    useEffect(() => {

        const ini = async() => {
            const sessionId = searchParams.get('session') || '';
            const bookingId = searchParams.get('bid');

            if(sessionId != null && bookingId != null){
                const nBId = Number(bookingId);
                await bookAllProducts(userToken, sessionId, nBId);
                await handOffBooking(userToken, sessionId, nBId);

                const ddDetail = sessionStorage.getItem('ddDetail');
                if(ddDetail != null){
                  await setUpDirectDebit(nBId, sessionId);
                }

                setBookedProcessCompleted(true);
            }
        };

        ini();

    }, []);

    useEffect(() => {
        if(!bookingCompleted){
            return
        }

        setTimeout(() => {
            const sessionId = searchParams.get('session') || '';
            const bookingId = searchParams.get('bid');
            siteNavigate(configuration, `/booking-confirmed/?session=${sessionId}&bid=${bookingId}`, fNav);
        }, 1000);

    }, [bookingCompleted])


    let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
    if(configuration != null){
      logoSrc = configuration.logo_file;
    }

    return (

        <div className={`loader-page-container`}>
        <img className='loader-page-logo' src={logoSrc} height={100} />
        <Loader
          loading={{
            booking:bookingCompleted
          }}
        />
   
        </div>
  )
}