import React, { useState, useEffect } from 'react';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { BaseLayout } from '../../components/BaseLayout';


export function RyanAirConfirm() {

    const {
        RootStore: {
            configuration,
        },
    } = useStores() as { RootStore: RootStore };


    useEffect(() => {

        window.addEventListener('message', msg => {
            if (msg.data.type === 'RYANAIR_TELEMETRY') {
                const payload = msg.data.payload;
                if (payload.state === 'REDIRECT' && payload.status === 200) {
                    // flight confirmation has been completed!
                    console.log('flight confirmation successful!');
                    //redirect to book
                }
            }
        }, false);

    }, []);

    const ryanAirSession = "abc";
    const src = `https://ryanairbc.paxport.net/confirm?agent=${configuration.ryr_agt}&session=${ryanAirSession}}&target=test`;

    return (


        <BaseLayout

            userToken={configuration.userToken}
            userConfiguration={configuration}
        >


            <div style={{ display: 'flex', justifyContent: 'center', padding:'0px' }}>
                <h2>Confirm with Ryanair</h2>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', paddingLeft:'15px', paddingRight: '15px' }}>
                <p>Please confirm using the section below that the price of your flight quoted by us at <b>£xx.xx</b> matches the Ryanair price.</p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '25px', marginRight: '25px', marginBottom: '10px' }}>
                <iframe
                    id="ryr-flight-confirm-frame"
                    src={"https://bbc.co.uk"} width="100%" height="600"
                    sandbox="allow-scripts allow-same-origin">
                    <p>iFrame not loaded!</p>
                </iframe>
            </div>

            {/* <iframe
            id="ryr-flight-confirm-frame"
            src={src} width="800" height="850"
            sandbox="allow-scripts allow-same-origin">
            <p>iFrame not loaded!</p>
        </iframe> */}

        </BaseLayout>)
}