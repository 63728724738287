import React, { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'

import { Button } from '../../Button/Button';

import plane from '../../assets/plane-big.svg';
import planeDefault from '../../assets/icons/default/03/Plane.svg';

import ezyLogo from '../../assets/airline/ezy.svg';
import tomLogo from '../../assets/airline/tom.svg';
import jet2 from '../../assets/airline/jt2.svg';
import freeLogo from '../../assets/airline/freebird-logo.svg';
import aegLogo from '../../assets/airline/aeg.svg';
import corLogo from '../../assets/airline/cor.svg';

import './FlightDetails.scss';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';

interface FlightDetailsProps {
  flightType: string;
  airline: string;
  flightNumber: string;
  departureTime: string;
  departurePlace: string;
  arrivalPlace: string;
  arrivalTime: string;
  outBound?: boolean;
  operatedBy?: string;
  config:any;
}

const FlightDetails: FunctionComponent<FlightDetailsProps> = ({
  flightType,
  airline,
  flightNumber,
  departureTime,
  departurePlace,
  arrivalPlace,
  arrivalTime,
  outBound,
  operatedBy,
  config
}: FlightDetailsProps) => {



  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const airlineLogos = [
    { airline: 'eju', logo: ezyLogo },
    { airline: 'easyjet', logo: ezyLogo },
    { airline: 'ezy', logo: ezyLogo },
    { airline: 'tom', logo: tomLogo },
    { airline: 'tui airways', logo: tomLogo },
    { airline: 'jet2', logo: jet2 },
    { airline: 'ls', logo: jet2 },
    { airline: 'freebird', logo: freeLogo },
    { airline: 'fhy', logo: freeLogo },
    { airline: 'freebird', logo: freeLogo },
    { airline: 'a3', logo: aegLogo },
    { airline: 'aegean air', logo: aegLogo },
    { airline: 'corendon', logo: corLogo },
    { airline: 'xc', logo: corLogo },
  ];
  let airlineLogoImage = null;
  for (const x of airlineLogos) {
    if (x.airline.toLowerCase() == airline.toLowerCase()) {
      airlineLogoImage = <img style={{ maxWidth: '120px' }} src={x.logo}></img>;
    }
  }

  if (airlineLogoImage == null) {
    airlineLogoImage = airline;
  }

  let opBy = null;
  if(operatedBy != null){
    if(operatedBy != airline){
      opBy = <span style={{fontSize: '0.55rem'}}>Operated by {operatedBy}</span>
    }
  }

  dayjs.extend(AdvancedFormat)

  const strDate = dayjs(departureTime).format('ddd Do MMM YYYY');


  let planeIco = planeDefault;
  if(configuration.icon_set == 'blue'){
    planeIco = plane;
  }

  return (
    <>
      <div style={{color: config.text_colour}} className='flight-details-wrapper'>
        <div className='flight-details--top-section'>
          <div className='top-date-details'>
            <img src={planeIco} alt='' />
            <div className={'info-section'}>
              <span className='title'>{strDate}</span>
              <span style={{color: config.text_colour_tertiary}} className='sub-title'>{flightType}</span>
            </div>
          </div>
          <div className='flight-details--main-section'>
            <ul className='route-point'>
              <li className={'route-point--item'}>
                <span style={{color: config.text_colour}} className='time'>{dayjs(departureTime).format('HH:mm')}</span>
                <span style={{color: config.text_colour}} className='place-name'>{departurePlace}</span>
                <span style={{color: config.text_colour}} className='flight-time'>{}</span>
              </li>
              <li className={'route-point--item'}>
                <span style={{color: config.text_colour}} className='time'>{dayjs(arrivalTime).format('HH:mm')}</span>
                <span style={{color: config.text_colour}} className='place-name'>{arrivalPlace}</span>
              </li>
            </ul>
          </div>
  
            <div className='flight-info'>
              {airlineLogoImage && <span className='airline-logo-text'>{airlineLogoImage}</span>}
              {flightNumber && <span style={{color: config.text_colour}} className='airline-code'>({flightNumber})</span>}
              {opBy}
            </div>
    
        </div>
      </div>

      {/* {isViewAlternatives && (
        <div className='flight-details-wrapper flight-details-wrapper--no-border'>
          <Button onClick={onViewAlternativeClick} label={'View alternative flights'} backgroundColor={config.button_colour} />
        </div>
      )} */}
    </>
  );
};

export default FlightDetails;
