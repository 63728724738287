import { FC, useState, useEffect } from 'react';
import './SelectLounge.scss';
import loungeIcon from '../assets/lounge.svg';
import { Button } from '../Button';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { addProduct, removeProduct } from '../../hooks/useRequest';
import { Lounge, Basket } from '../../hooks/types';
import { LoaderSpinnerSmall } from '../Loader/jLoader'

interface SelectLoungeProps {
  sessionId: string;
  options: Lounge[];
  selectedLounge: Lounge | null;  
  basketData: Basket;
  onClick?: (transfer?: Lounge | null) => void;
}

const SelectLounge: FC<SelectLoungeProps> = (
  { 
    sessionId, 
    options,   
    selectedLounge,
    basketData,
    onClick 
  }) => {

  const [selected, setSelected] = useState<Lounge | null>();
  const [buttonLoad, setButtonLoad] = useState(false);
  const [selectingTransfer, setSelecting] = useState({'id': -2});

  const {
    RootStore: {
      userToken,
      configuration
    },
  } = useStores() as { RootStore: RootStore };
  
  useEffect(() => {
    setSelected(selectedLounge);
  }, [selectedLounge])


  const compareParkingPrice = (selectedLoungePrice: any, lounge: Lounge) => {
    let diff = 0 as string | number;
    let strDiff = '';

    if (lounge.price != null) {
      if (selectedLoungePrice > lounge.price) {
        diff = (selectedLoungePrice - lounge.price).toFixed(2);
        strDiff = '- £' + diff;
      } else if (selectedLoungePrice < lounge.price) {
        diff = (lounge.price - selectedLoungePrice).toFixed(2);
        strDiff = '+ £' + diff;
      } else {
        diff = 0;
        strDiff = '';
      }
    }

    return strDiff;
  };


  async function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
   }



  async function selectNotRequired (){

    setSelecting({'id': -1})
    setButtonLoad(true);

    if(selected){
      await removeProduct(userToken, sessionId, selected.id, 14);
    }

    setSelected(null);
    setButtonLoad(false);
    setSelecting({'id': -2})

    if (onClick) {
      onClick(null);
    }

  }

  async function selectProduct (selected: number, lounge: Lounge){
    if (!sessionId) {
      return;
    }


    setSelecting({'id': selected || -2})
    setButtonLoad(true);

    await addProduct(userToken, sessionId, lounge.id, 14, true);
    
    if (onClick) {
      onClick(lounge);
    }

    setSelected(lounge);
    setButtonLoad(false);
    setSelecting({'id': -2})
  };


  if(options.length == 0){
    return null;
  }
  
  let selectedPrice = 0;

  if(selected){
    options.map((parking) => {
      if (parking.id == selected.id) {
        if (parking.price != null) {
          selectedPrice = parking.price;
        }
      }
    });
  }


  const selStyleNone = {borderColor: ''};
  let isSelectedNone = false;
  if(selected == null){
    isSelectedNone = true;
    selStyleNone.borderColor = configuration.jrny.selected_button_border_colour;;
  }

    let mobButtonNone = <Button
        hoverStyle={true}
        isSelected={isSelectedNone}
        overrideClassNames={`select-lounge-button ${
          selected == null ? 'select-lounge-button-active' : ''
        }`}
        label={selected == null? 'Selected' : 'Select'}
        isTransparent={false}
        onClick={() => {
            selectNotRequired();

        }}
        color={configuration.button_text_secondary_colour}
        disabled={isSelectedNone}
      />
    
    if(buttonLoad){

      if(-1 == selectingTransfer.id){
        mobButtonNone = <div className='select-lounge-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
        borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>

      }else{
        mobButtonNone = <Button
        hoverStyle={true}
        isSelected={isSelectedNone}
        overrideClassNames={`select-lounge-button ${
          selected == null ? 'select-lounge-button-active' : ''
        }`}
        label={selected == null ? 'Selected' : 'Select'}
        isTransparent={false}
        onClick={() => {
            selectNotRequired();
        }}
        color={configuration.button_text_secondary_colour}
        disabled={true}
      />
      }
    }



  return (
    <div>
      <div className='select-lounge-container'>
        <h4 className='select-lounge-title'>
          <img className='select-lounge-icon' src={loungeIcon} height='28' width='28' />
          <span className='select-lounge-title-text'>Airport Lounges</span>
        </h4>

        {/* Mobile */}
        <div
          style={selStyleNone}
          className={'select-lounge-container-child-mobile'}
        >
          <div>
            <h4>Not Required</h4>
          </div>

          <div className='select-lounge-button-wrap'>
            {mobButtonNone}
          </div>
        </div>
        {options.map((selectParkingOption, index) => {

          const detail = [""];
          const strDetail = detail.join(' ');

          const diff = compareParkingPrice(selectedPrice, selectParkingOption);
          let diffV = null;
          if (diff != '') {
            diffV = (
              <span>
                {diff} <br />{' '}
              </span>
            );
          }

          const selStyle = {borderColor: ''};
          let isSelected = false;
          if(selected?.id == selectParkingOption.id){
            isSelected = true;
            selStyle.borderColor = configuration.jrny.selected_button_border_colour;
          }

        let mobButton = <Button
                hoverStyle={true}
                isSelected={isSelected}
                overrideClassNames={`select-lounge-button ${
                  selected?.id === selectParkingOption.id
                    ? 'select-lounge-button-active'
                    : ''
                }
                ${selectParkingOption.price ? 'select-lounge-button-has-price' : ''}`}
                label={selected?.id === selectParkingOption.id ? 'Selected' : 'Select'}
                isTransparent={false}
                onClick={() => {
                    selectProduct(selectParkingOption.id, selectParkingOption);
                }}
                color={configuration.button_text_secondary_colour}
                disabled={isSelected}
              />;

        if(buttonLoad){
    
          if(selectParkingOption.id == selectingTransfer.id){
            mobButton = <div className='select-lounge-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
            borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
          }else{
            mobButton = <Button
            hoverStyle={true}
            isSelected={isSelected}
            overrideClassNames={`select-lounge-button ${
              selected?.id === selectParkingOption.id
                ? 'select-lounge-button-active'
                : ''
            }
            ${selectParkingOption.price ? 'select-lounge-button-has-price' : ''}`}
            label={selected?.id === selectParkingOption.id ? 'Selected' : 'Select'}
            isTransparent={false}
            onClick={() => {
                selectProduct(selectParkingOption.id, selectParkingOption);
            }}
            color={configuration.button_text_secondary_colour}
            disabled={true}
          />
          }
        }

          return (
            <div
              style={selStyle}
              key={selectParkingOption.id + 'selectParkingOption'}
              className={'select-lounge-container-child-mobile'}
            >
              <div>
                <h4 className='select-lounge-heading'>{selectParkingOption.description}</h4>
                <div className='select-lounge-container-duration'>
                  <b>{strDetail}</b>
                </div>
              </div>
              <div>
                {selectParkingOption.price && (
                  <div className='select-lounge-container-price'>
                    <div style={{color: configuration.jrny.price_colour}} className='select-lounge-container-price__value'>{diffV}</div>
                  </div>
                )}

                <div className='select-lounge-button-wrap'>
                  {mobButton}
                </div>
              </div>
            </div>
          );
        })}

        {/* Desktop */}
        <div
          style={selStyleNone}
          className={'select-lounge-container-child'}
        >
          <div>
            <h4>Not Required</h4>
          </div>

          <div className='select-lounge-button-wrap'>
            {mobButtonNone}
          </div>
        </div>
        {options.map((selectParkingOption, index) => {

        const detail = [""];
        const strDetail = detail.join(' ');

          const diff = compareParkingPrice(selectedPrice, selectParkingOption);
          let diffV = null;
          if (diff != '') {
            diffV = (
              <span>
                {diff} <br />{' '}
              </span>
            );
          }

          const selStyle = {borderColor: ''};
          let isSelected = false;
          if(selected?.id == selectParkingOption.id){
            isSelected = true;
            selStyle.borderColor = configuration.jrny.price_colour;
          }


          let deskButton =  <Button
          hoverStyle={true}
          isSelected={isSelected}
          overrideClassNames={'select-lounge-button'}
          label={selected?.id === index + 1 ? 'Selected' : 'Select'}
          isTransparent={false}
          onClick={() => {
            selectProduct(selectParkingOption.id, selectParkingOption);
          }}
          color={configuration.button_text_secondary_colour}
          disabled={isSelected}
        />

        if(buttonLoad){

          if(selectParkingOption.id == selectingTransfer.id){
            deskButton = <div className='select-lounge-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
            borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
          }else{
            deskButton =  <Button
            hoverStyle={true}
            isSelected={isSelected}
            overrideClassNames={'select-lounge-button'}
            label={selected?.id === index + 1 ? 'Selected' : 'Select'}
            isTransparent={false}
            onClick={() => {
                selectProduct(selectParkingOption.id, selectParkingOption);
            }}
            color={configuration.button_text_secondary_colour}
            disabled={true}
          />
          }
        }

          return (
            <div
              style={selStyle}
              key={selectParkingOption.id + 'selectParkingOption'}
              className={'select-lounge-container-child'}
            >
              <div>
                <h4>{selectParkingOption.description}</h4>
              </div>
              <div className='select-lounge-container-duration'>
                <b>{strDetail}</b>
              </div>
              <div style={{color: configuration.jrny.price_colour}} className='select-lounge-container-price'>{diffV}</div>

              <div className='select-lounge-button-wrap'>
                {deskButton}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SelectLounge };
