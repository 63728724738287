import { FC, useState, useEffect } from 'react';
import './SelectTransfer.scss';
import TaxiIcon from '../assets/taxi.svg';
import { Button } from '../Button';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { addProduct, removeProduct } from '../../hooks/useRequest';
import { Transfer, Basket } from '../../hooks/types';
import { LoaderSpinnerSmall } from '../Loader/jLoader'

interface SelectTransferProps {
  sessionId: string;
  options: Transfer[];
  selectedTransfer: Transfer | null;  
  basketData: Basket;
  onClick?: (transfer?: Transfer | null) => void;
}

const SelectTransfer: FC<SelectTransferProps> = (
  { 
    sessionId, 
    options,   
    selectedTransfer,
    basketData,
    onClick 
  }) => {

  const [selected, setSelected] = useState<Transfer | null>();
  const [buttonLoad, setButtonLoad] = useState(false);
  const [selectingTransfer, setSelecting] = useState({'id': -2});

  const {
    RootStore: {
      userToken,
      configuration
    },
  } = useStores() as { RootStore: RootStore };
  
  useEffect(() => {
    setSelected(selectedTransfer);
  }, [selectedTransfer])


  const compareTransferPrice = (selectTransfrerPrice: any, transfer: Transfer) => {
    let diff = 0 as string | number;
    let strDiff = '';

    if (transfer.price != null) {
      if (selectTransfrerPrice > transfer.price_per_person) {
        diff = (selectTransfrerPrice - transfer.price_per_person).toFixed(2);
        strDiff = '- £' + diff;
      } else if (selectTransfrerPrice < transfer.price_per_person) {
        diff = (transfer.price_per_person - selectTransfrerPrice).toFixed(2);
        strDiff = '+ £' + diff;
      } else {
        diff = 0;
        strDiff = '';
      }
    }

    return strDiff;
  };


  async function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
   }



  async function selectNoTransfer (){

    setSelecting({'id': -1})
    setButtonLoad(true);

    if(selected){
      await removeProduct(userToken, sessionId, selected.id, 3);
    }

    setSelected(null);
    setButtonLoad(false);
    setSelecting({'id': -2})

    if (onClick) {
      onClick(null);
    }

  }

  async function selectTransfer (selected: number, transfer: Transfer){
    if (!sessionId) {
      return;
    }


    setSelecting({'id': selected || -2})
    setButtonLoad(true);


    // if (transfer == null) {

    //   let existingTransfer = -1;
    //   for (const x of basket.products) {
    //     if (x.product_type.id == 3) {
    //       existingTransfer = x.id;
    //     }
    //   }

    //   if (existingTransfer != -1) {
    //     await removeProduct(userToken, sessionId, existingTransfer, 3);
    //   }
    // }


    await addProduct(userToken, sessionId, transfer.id, 3, true);
    
    if (onClick) {
      onClick(transfer);
    }

    setSelected(transfer);
    setButtonLoad(false);
    setSelecting({'id': -2})
  };


  if(options.length == 0){
    return null;
  }

  let selectedPrice = 0;

  if(selected){
    options.map((transfer) => {
      if (transfer.id == selected.id) {
        if (transfer.price != null) {
          selectedPrice = transfer.price_per_person;
        }
      }
    });
  }


  const selStyleNone = {borderColor: ''};
  let isSelectedNone = false;
  if(selected == null){
    isSelectedNone = true;
    selStyleNone.borderColor = configuration.jrny.selected_button_border_colour;;
  }

    let mobButtonNone = <Button
        hoverStyle={true}
        isSelected={isSelectedNone}
        overrideClassNames={`select-transfer-button ${
          selected == null ? 'select-transfer-button-active' : ''
        }`}
        label={selected == null? 'Selected' : 'Select'}
        isTransparent={false}
        onClick={() => {
          selectNoTransfer();

        }}
        color={configuration.button_text_secondary_colour}
        disabled={isSelectedNone}
      />
    
    if(buttonLoad){

      if(-1 == selectingTransfer.id){
        mobButtonNone = <div className='select-transfer-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
        borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>

      }else{
        mobButtonNone = <Button
        hoverStyle={true}
        isSelected={isSelectedNone}
        overrideClassNames={`select-transfer-button ${
          selected == null ? 'select-transfer-button-active' : ''
        }`}
        label={selected == null ? 'Selected' : 'Select'}
        isTransparent={false}
        onClick={() => {
          selectNoTransfer();
        }}
        color={configuration.button_text_secondary_colour}
        disabled={true}
      />
      }
    }

  return (
    <div>
      <div className='select-transfer-container'>
        <h4 className='select-transfer-title'>
          <img className='select-transfer-icon' src={TaxiIcon} height='20' width='20' />
          <span className='select-transfer-title-text'>Transfers</span>
        </h4>

        {/* Mobile */}
        <div
          style={selStyleNone}
          className={'select-transfer-container-child-mobile'}
        >
          <div>
          <h4>Not Required</h4>
          </div>

          <div className='select-transfer-button-wrap'>
            {mobButtonNone}
          </div>
        </div>
        {options.map((selectTransferOption, index) => {
          const diff = compareTransferPrice(selectedPrice, selectTransferOption);
          let diffV = null;
          if (diff != '') {
            diffV = (
              <span>
                {diff} <br />{' '}
                <span className='select-transfer-container-per-person'>Per Person</span>
              </span>
            );
          }

          const selStyle = {borderColor: ''};
          let isSelected = false;
          if(selected?.id == selectTransferOption.id){
            isSelected = true;
            selStyle.borderColor = configuration.jrny.selected_button_border_colour;
          }

        let mobButton = <Button
                hoverStyle={true}
                isSelected={isSelected}
                overrideClassNames={`select-transfer-button ${
                  selected?.id === selectTransferOption.id
                    ? 'select-transfer-button-active'
                    : ''
                }
                ${selectTransferOption.price ? 'select-transfer-button-has-price' : ''}`}
                label={selected?.id === selectTransferOption.id ? 'Selected' : 'Select'}
                isTransparent={false}
                onClick={() => {
                  selectTransfer(selectTransferOption.id, selectTransferOption);
                }}
                color={configuration.button_text_secondary_colour}
                disabled={isSelected}
              />;

        if(buttonLoad){
    
          if(selectTransferOption.id == selectingTransfer.id){
            mobButton = <div className='select-transfer-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
            borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
          }else{
            mobButton = <Button
            hoverStyle={true}
            isSelected={isSelected}
            overrideClassNames={`select-transfer-button ${
              selected?.id === selectTransferOption.id
                ? 'select-transfer-button-active'
                : ''
            }
            ${selectTransferOption.price ? 'select-transfer-button-has-price' : ''}`}
            label={selected?.id === selectTransferOption.id ? 'Selected' : 'Select'}
            isTransparent={false}
            onClick={() => {
              selectTransfer(selectTransferOption.id, selectTransferOption);
            }}
            color={configuration.button_text_secondary_colour}
            disabled={true}
          />
          }
        }

          return (
            <div
              style={selStyle}
              key={selectTransferOption.id + 'selectTransferOption'}
              className={'select-transfer-container-child-mobile'}
            >
              <div>
                <h4 className='select-transfer-heading'>{selectTransferOption.vehicle_type}</h4>
                <div className='select-transfer-container-duration'>
                  <b>{selectTransferOption.duration}</b>
                </div>
              </div>
              <div>
                {selectTransferOption.price && (
                  <div className='select-transfer-container-price'>
                    <div style={{color: configuration.jrny.price_colour}} className='select-transfer-container-price__value'>{diffV}</div>
                  </div>
                )}

                <div className='select-transfer-button-wrap'>
                  {mobButton}
                </div>
              </div>
            </div>
          );
        })}

        {/* Desktop */}
        <div
          style={selStyleNone}
          className={'select-transfer-container-child'}
        >
          <div>
          <h4>Not Required</h4>
          </div>

          <div className='select-transfer-button-wrap'>
            {mobButtonNone}
          </div>
        </div>
        {options.map((selectTransferOption, index) => {
          const diff = compareTransferPrice(selectedPrice, selectTransferOption);
          let diffV = null;
          if (diff != '') {
            diffV = (
              <span>
                {diff} <br />{' '}
                <span className='select-transfer-container-per-person'>Per Person</span>
              </span>
            );
          }

          const selStyle = {borderColor: ''};
          let isSelected = false;
          if(selected?.id == selectTransferOption.id){
            isSelected = true;
            selStyle.borderColor = configuration.jrny.price_colour;
          }


          let deskButton =  <Button
          hoverStyle={true}
          isSelected={isSelected}
          overrideClassNames={'select-transfer-button'}
          label={selected?.id === index + 1 ? 'Selected' : 'Select'}
          isTransparent={false}
          onClick={() => {
            selectTransfer(selectTransferOption.id, selectTransferOption);
          }}
          color={configuration.button_text_secondary_colour}
          disabled={isSelected}
        />

        if(buttonLoad){

          if(selectTransferOption.id == selectingTransfer.id){
            deskButton = <div className='select-transfer-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
            borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
          }else{
            deskButton =  <Button
            hoverStyle={true}
            isSelected={isSelected}
            overrideClassNames={'select-transfer-button'}
            label={selected?.id === index + 1 ? 'Selected' : 'Select'}
            isTransparent={false}
            onClick={() => {
              selectTransfer(selectTransferOption.id, selectTransferOption);
            }}
            color={configuration.button_text_secondary_colour}
            disabled={true}
          />
          }
        }

          return (
            <div
              style={selStyle}
              key={selectTransferOption.id + 'selectTransferOption'}
              className={'select-transfer-container-child'}
            >
              <div>
                <h4>{selectTransferOption.vehicle_type}</h4>
              </div>
              <div className='select-transfer-container-duration'>
                <b>{selectTransferOption.duration}</b>
              </div>
              <div style={{color: configuration.jrny.price_colour}} className='select-transfer-container-price'>{diffV}</div>

              <div className='select-transfer-button-wrap'>
                {deskButton}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SelectTransfer };
