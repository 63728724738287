import { FunctionComponent, useEffect, useMemo, useState  } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { isStringEmpty } from '../../services/str';
import { Modal } from '../../components/Modal';
import dayjs from 'dayjs';
import { siteNavigate } from '../../services/nav';

interface PackageBootProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

export const LoaderSpinner = () => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
      bs = '#01447b';
  }

  return (
    <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
      <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
    </div>
  );
};

export const PackageBootPage: FunctionComponent<PackageBootProps> = ({
  onLogin = noop,
  openMenu = noop,
}) => {


  const {
    RootStore: {
        sessionStore: { userSession, createUserSession },
        userToken,
        configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [isExpired, setExpired] = useState<boolean>(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }


  const query = useQuery();

  const fNav = useNavigate();

  useEffect(() => {

    const ini = async() => {
        sessionStorage.removeItem('snpOff');
    
        const rooms = query.get('rooms');

        const duration = query.get('duration');

        const departing = query.get('departing');
        
        const region = query.get('regions');

        const departurePoint = query.get('departurePoints');

        const session = await createUserSession(userToken, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 2, undefined, -1);

        const queryString = `rooms=${rooms}&duration=${duration}&departing=${departing}&departingType=0&regions=${region}&departurePoints=${departurePoint}&type=2&session=${session}`

        siteNavigate(configuration,`/package-search?${queryString}`, fNav);
    }
    ini();
  }, []);


  return (
    <BaseLayout
      onSearchButtonClick={() => null}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='booking-confirmed-page booking-confirmed-page--failed'>
        <div className='booking-confirmed-page-wrapper'>
          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title booking-title--normal'>
              Just a moment ...
            </p>
            {LoaderSpinner()}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}