import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { bookAllProducts, handOffBooking, paymentAuth, siteConfiguration, setupPaymentPlan, } from '../../hooks/useRequest';

import { Loader } from '../../components/Loader/LoaderThree';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { siteNavigate } from '../../services/nav';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

type PaymentResult = {
  payment_auth: {
    result: {
      completed: number;
      payment_result: string;
    };
  };
};
export function PaymentInterstitialPage() {

  const {
    RootStore: {
      userToken, 
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  const [payCompleted, setPaymentProcessCompleted] = useState<boolean>(false);

  const query = useQuery();
  const fNav = useNavigate();

  // Method to handle booking process
  const processBooking = async (bookingId: number, sessionId: string) => {
    try {

      const bookComs = await siteConfiguration('12', userToken)
      if(bookComs == 0){
        siteNavigate(configuration, `/booking-confirmed/?session=${sessionId}&bid=${bookingId}`, fNav);
        return;
      }

      // await bookAllProducts(userToken, sessionId, bookingId);
      // await handOffBooking(userToken, sessionId, bookingId);
      // const ddDetail = sessionStorage.getItem('ddDetail');
      // if(ddDetail != null){
      //   await setUpDirectDebit(bookingId, sessionId);
      // }
      setPaymentProcessCompleted(true);
    } catch (error) {
      console.log('Unable to process booking', error);
      siteNavigate(configuration,'/booking-failed?error=booking-failed', fNav);
    }
  };

  useEffect(() => {

      if(!payCompleted){
          return
      }

      setTimeout(() => {
          const bid = parseInt(query.get('bid') || '');
          const session = query.get('session') || '';
          siteNavigate(configuration, `/booking/?session=${session}&bid=${bid}`, fNav);
      }, 1000);

  }, [payCompleted])

  useEffect(() => {
    if (!query.get('bid') || !query.get('session') || !query.get('payment_session')) {
      siteNavigate(configuration, '/', fNav);
    } else {
      const paymentSession = query.get('payment_session');

      if (paymentSession) {
        const bid = parseInt(query.get('bid') || '');
        const session = query.get('session') || '';

        paymentAuth(userToken, paymentSession)
          .then((res) => {
            const data = res as PaymentResult;
            // If we have a result...
            if (data?.payment_auth?.result?.payment_result) {
              const result = data?.payment_auth?.result?.payment_result;
              if (result === 'fail') {
                console.log('Redirect with ?error=payment-failed');
                // siteNavigate(configuration, '/booking-failed?error=payment-failed', fNav);
                siteNavigate(configuration, `/payment?session=${session}&f=1`, fNav);

              } else {
                // Proceed with placing the booking, this needs moving into payment
                // once we know this works
                processBooking(bid, session);
              }
            } else {
              console.log('Redirect with ?error=payment-failed');
              siteNavigate(configuration, '/booking-failed?error=payment-failed', fNav);     
            }
          })
          .catch((error) => {
            console.log('Here is the error', error);
            siteNavigate(configuration, '/booking-failed?error=booking-failed', fNav);
          });
      }
    }
  }, []);

  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }

  return (
    <div className={`loader-page-container`}>
    <img className='loader-page-logo' src={logoSrc} height={100} />
    <Loader
      loading={{
        complted:payCompleted
      }}
    />

    </div>
  );
}
