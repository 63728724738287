import { FC } from 'react';
import { ILoaderGen } from './Interfaces';
import './Loader.scss';

import IconLoaderTick from '../assets/loader-tick.svg';


import AtolIcon from '../assets/atol.svg';
import AtbaIcon from '../assets/atba.svg';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';


export const LoaderSpinner = () => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


let bs = configuration.button_colour
if(configuration.spec == '6yk1y'){;
  bs = '#01447b';
}

  return (
    <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
      <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
    </div>
  );
};

export const LoaderTick = () => {
  return (
    <div>
      <div className='loader-tick'>
        <img src={IconLoaderTick} height={40} width={40} />
      </div>
    </div>
  );
};

export const Loader: FC<ILoaderGen> = ({ loading }) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  return (
    <div className='loader-container'>
      <div className={`loader-nogrid`}>

   
          <div>
            <div style={{color: configuration.text_colour}}> {loading.complted}</div>
   
            <div className='loader-icons-container'>
              {!loading.complted && <LoaderSpinner />}
              {loading.complted && <LoaderTick />}
            </div>
          </div>
        

      </div>
      <span style={{color: configuration.text_colour}}>Authorising payment. Please wait.<br></br><br></br> Do not refresh the page or navigate away from this page.</span>
      <div className='abta-atol-logo'>
        <img width={150} src={AtbaIcon} />
        <img width={50} src={AtolIcon} />
      </div>
    </div>
  );
};
