// import { useNavigate } from 'react-router-dom';

import { NavigateFunction, NavigateOptions } from "react-router-dom";

export function siteNavigate(configuration: any, newLocation: string, x: NavigateFunction, options?: NavigateOptions | null | undefined){
    if(configuration.spec == '6yk1y'){
        window.location.href = newLocation;
    }
    else{
        if(options != null){
            x(newLocation, options)
        }
        x(newLocation);
    }
}