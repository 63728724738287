import {
    getBasket,
    getBasketFlights,
    getBasketTransfer,
    getAllRoomsFromBasket,
    getPackageRoomsFromBasket,
    getPackageDetails,
    getBasketAllProducts,
    getBasketPackageAllProducts
  } from '../hooks/useRequest';


export async function basket(userToken: string, sessionId: string){

    const basketResponse = await getBasket(userToken, sessionId);

    if(basketResponse.basket.result == null){
        return null;
    }

    return basketResponse.basket.result;
}

export async function basketRooms(userToken: string, sessionId: string){

    const basketRoomsResponse = await getAllRoomsFromBasket(userToken, sessionId);
    if(basketRoomsResponse.basket_rooms.result == null){
        return null;
    }

    return basketRoomsResponse.basket_rooms.result;
}

export async function basketFlights(userToken: string, sessionId: string, flightId: number){
    const basketFlightResponse = await getBasketFlights(userToken, sessionId, flightId)
    if(basketFlightResponse.basket_flight.result != null){
        return basketFlightResponse.basket_flight.result;
    }
    return null;
}

export async function getBasketDP(userToken: string, sessionId: string){

    const basketResponse = await getBasketAllProducts(userToken, sessionId);

    if(basketResponse.basket_products.result == null){
        return null;
    }

    const b = basketResponse.basket_products.result;

    const rms = b.rooms;

    let f = null;
    if(b.flights.length > 0){
        f = b.flights[0];
    }

    let tr = null;
    if(b.transfers.length > 0){
        tr = b.transfers[0];
    }

    let p = null;
    if(b.parking.length > 0){
        p = b.parking[0];
    }

    let aa = null;
    if(b.airport_accommodation.length > 0){
        aa = b.airport_accommodation[0];
    }

    let l = null;
    if(b.lounge.length > 0){
        l = b.lounge[0];
    }



    return {basket: b.basket, rooms: rms, flight: f, transfer: tr, parking: p, airport_accommodation: aa, lounge: l};
}


export async function getBasketPackage(userToken: string, sessionId: string){

    const basketResponse = await getBasketPackageAllProducts(userToken, sessionId);

    if(basketResponse.basket_package_products.result == null){
        return null;
    }
    const b = basketResponse.basket_package_products.result;


    return {basket: b.basket, package: b.package, rooms: b.rooms};

}