import { FunctionComponent, useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';

import './SelectPagePackage.scss';

import PackageFlight from '../../components/PackageFlight/PackageFlight';

import foodDefault from '../../components/assets/icons/default/05/Food.svg';
import food from '../../components/assets/food.svg';

import {
  getSessionSelections,
  sessionUpdateSelections,
  addProducts,
  getBasket,
  getPackageDetails,
  paymentPayMonthly,
  getPackageRoomFromBasket,
  journeyAdd,
  sessionSave,
  productCheckAll
} from '../../hooks/useRequest';

import { PackageBasket } from '../../components/Basket/PackageBasket';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { Button } from '../../components/Button';

import { useSearchParams, useNavigate } from 'react-router-dom';
import { SelectBoard } from '../../components/SelectBoard';
import { Board } from '../../components/SelectBoard/SelectBoard';

import { ProgressBarPackage } from '../../components/ProgressBar/ProgressBarPackage';

import { Package, Basket as BasketData, PackageRoom, Accommodation, PackageByIdResult, Selections, SearchSelectionRoom } from '../../hooks/types';

import { boardFromID, accommodation as getAccommodation } from '../../services/acc';
import { adultCountFromRooms, childCountFromRooms, roomsToStringURL, childAgesFromRooms, roomsFromPaxString } from '../../services/pax';
import { sessionGetSearchParams } from '../../services/search';

import { PackageRoomSelection } from '../../components/PackageRoomSelection';

import { PackageSelectLoader } from '../../components/PackageSelectLoader/PackageSelectLoader';
import { siteNavigate } from '../../services/nav';

interface HomePageProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

const PackageSelectPage: FunctionComponent<HomePageProps> = ({
  onLogin = noop,
  openMenu = noop,
}) => {
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const fNav = useNavigate();
  const [searchParams] = useSearchParams();

  const s = searchParams.get('session');

  const selectedSearchParams = sessionGetSearchParams();

  const [sessionId] = useState<string>(s || '');

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);

  const [boardDetails, setBoardDetails] = useState<Board[]>([]);

  const [roomDetailsFiltered, setRoomDetailsFiltered] = useState<any>([]);

  const [selectedRooms, setSelectedRooms] = useState<PackageRoom[]>([]);

  const [everythingLoaded, setEverythingLoaded] = useState<boolean>(false);

  const [selectedBoardId, setSelectedBoardId] = useState<number>(-1);

  const [packageHoliday, setPackageHoliday] = useState<Package>();

  const [pricePlan , setPaymentPlan] = useState<any>(null);

  const [basket , setBasket] = useState<BasketData>();

  const [accommodation, setAccommodation] = useState<Accommodation>();

  const [packageId, setPackageId] = useState<number>();


  const [selections, setSelections] = useState<Selections>();
  const [searchedRooms, setSearchedRooms] = useState<SearchSelectionRoom[]>([]);


  const roomBoardPrices = (boards:any, rgroups: any, packageHolidayRooms: PackageRoom[]) => {
    const bbPrices = [];
    const bbD: number[] = [];

    for (const b of boards) {
      for (const rg of rgroups) {

        for (const r of packageHolidayRooms) {
          if (r.room_number == rg.roomNumber && r.board == b.id) {
            const bbid = Number(r.board);

            if (bbD.indexOf(bbid) != -1) {
              bbPrices[bbD.indexOf(bbid)].totalBbPrice += r.price_per_person;
              break;
            }
            bbPrices.push({ bb: bbid, totalBbPrice: r.price_per_person });
            bbD.push(bbid);
            break;
          }
        }
      }
    }
    return bbPrices;
  }

  const filterBoard = async (board: Board) => {
    if (sessionId == null) {
      return;
    }

    if(packageHoliday == null){
      return;
    }

    const rgroups: any[] = [];
    let roomNo = 1;
    for (const x of selectedRooms) {
      rgroups.push({ roomNumber: roomNo, rooms: [] });
      roomNo += 1;
    }

    const boards: Board[] = roomAvailBoards(packageHoliday.rooms);

    const bbPrices = roomBoardPrices(boards, rgroups, packageHoliday.rooms);


    for (const rg of rgroups) {
      for (const r of packageHoliday.rooms) {
        if (rg.roomNumber == r.room_number && r.board == board.id) {
          rg.rooms.push(r);
        }
      }
    }

    const selectRooms: PackageRoom[] = [];
    let selectedRoomPrices = 0;
    for (const rg of rgroups) {
      selectRooms.push(rg.rooms[0]);
      selectedRoomPrices += rg.rooms[0].price_per_person;
    }

    for (const bbp of bbPrices) {
      // work out the diff
      let diff = 0;
      let strDiff = '';
      if (selectedRoomPrices > bbp.totalBbPrice) {
        diff = Number(selectedRoomPrices - bbp.totalBbPrice);
        diff = diff / rgroups.length;

        strDiff = 'From - £' + diff.toFixed(2) + 'pp';
      } else if (selectedRoomPrices < bbp.totalBbPrice) {
        diff = Number(bbp.totalBbPrice - selectedRoomPrices);
        diff = diff / rgroups.length;

        strDiff = 'From + £' + diff.toFixed(2) + 'pp';
      } else {
        diff = 0;
        strDiff = '';
      }

      for (const bd of boards) {
        if (bd.id == bbp.bb) {
          bd.diff = diff;
          bd.diffStr = strDiff;
          break;
        }
      }
    }

    // await clearProducts(userSession.sessionId);

    const prods: number[] = [];

    for (const nsr of selectRooms) {
        prods.push(nsr.id)
    }

    await addProducts(userToken, sessionId, prods, 5, true);

    setSelectedRooms(selectRooms);
    setSessionRooms(selectRooms);
    setRoomDetailsFiltered(rgroups);
    setBoardDetails(boards);
    setSelectedBoardId(board.id);
    await loadBasket();


  };


  const setSessionRooms = (selectedRooms: PackageRoom[]) => {
    const selProds = [];
    for(const x of selectedRooms){
      selProds.push(x.id);
    }
    const custom = selProds.join('-') + '$' + packageId;
    sessionUpdateSelections(userToken, sessionId, selections?.accommodation_id, selections?.departure_code, selections?.arrival_code, selections?.departing, selections?.duration, selections?.board, selections?.rooms, selections?.trip_type, custom)

  }

  const updateSelectedRooms =  async (selectRooms: PackageRoom[]) => {
    setSelectedRooms(selectRooms);
    setSessionRooms(selectRooms);
    loadBasket();
  };



  const roomAvailBoards = (rooms: PackageRoom[]) => {
    const boards: Board[] = [];
    for (const r of rooms) {
      const bbid = Number(r.board);
      let w = true;
      for (const x of boards) {
        if (bbid == x.id) {
          w = false;
        }
      }

      if (!w) {
        continue;
      }

      boards.push({ id: r.board, price: r.price_per_person, diff: 0, diffStr: '', type: boardFromID(r.board) });
    }
    return boards;
  }

useEffect(() => {


    async function ini() {



      const selectionsResult = await getSessionSelections(userToken, sessionId);
      const sels = selectionsResult.session.result.selections;

      setSelections(sels);

      const selectedRooms = roomsFromPaxString(sels.rooms) as SearchSelectionRoom[];
      setSearchedRooms(selectedRooms);

      const packageId = Number(sels.custom.split('$')[1]);
      setPackageId(packageId);

      const sp = sels.custom.split('$')[0].split('-');
      const spn:number[] = [];
      for(const s of sp){
        spn.push(Number(s));
      }
      await addProducts(userToken, sessionId, spn, 5, true);

      const aid = sels.accommodation_id;

      const basket = await getBasket(userToken, sessionId);

      setBasket(basket.basket.result);

      let providerID = 4;
      for(const x of basket.basket.result.products){
        if(x.product_type.id == 5){
          providerID = x.provider_id;
        }
      }

      const a = await getAccommodation(userToken, Number(aid), providerID);
      if(a == null){
        return null;
      }

      setAccommodation(a);

      if (sessionId != null && selectedSearchParams != null) {
        const r = await sessionSave(userToken, sessionId, selectedSearchParams.searchType);
        const ob = {
          reference: r,
          steps: [1],
          email: '',
          contactEmail: '',
          contactNumber: '',
          type: selectedSearchParams.searchType
        }
        sessionStorage.setItem('urf' + sessionId, JSON.stringify(ob))
      }
      
  

     const selProds: PackageRoom[] = [];  
     const selProductIDs: number[] = [];
     let price = 0;
     let price_per_person = 0;

     for(const x of basket.basket.result.products){

      const baskRoom = await getPackageRoomFromBasket(userToken, sessionId, x.id);
      selProductIDs.push(x.id);
      selProds.push(baskRoom.basket_package.result);
      price += x.price;
      price_per_person += x.price_per_person;
     }

     setSelectedRooms(selProds);

     const pakDetail = await getPackageDetails(userToken, sessionId, Number(packageId), [])

     if (pakDetail.package.error) {
      //display a modal issue
      alert('Unable to load package..');
      return console.log('Unable to load package..', pakDetail.package.error);
    }

    setPackageHoliday(pakDetail.package.result);

      const rgroups: any[] = [];
      let roomNo = 1;
      for (const x of selProds) {
        rgroups.push({ roomNumber: roomNo, rooms: [] });
        roomNo += 1;
      }

      let selBoard = selProds[0].board;

      const boards: Board[] = roomAvailBoards(pakDetail.package.result.rooms);

      setSelectedBoardId(selBoard);

      for (const rg of rgroups) {
        for (const r of pakDetail.package.result.rooms) {
          if (rg.roomNumber == r.room_number && r.board == selBoard) {
            rg.rooms.push(r);
          }
        }
      }

      const bbPrices = roomBoardPrices(boards, rgroups, pakDetail.package.result.rooms);

      for (const bbp of bbPrices) {
        // work out the diff
        let diff = 0;
        let strDiff = '';
        if (price_per_person > bbp.totalBbPrice) {
          diff = Number(price_per_person - bbp.totalBbPrice);
          diff = diff / selProds.length;

          strDiff = 'From - £' + diff.toFixed(2) + 'pp';
        } else if (price_per_person < bbp.totalBbPrice) {
          diff = Number(bbp.totalBbPrice - price_per_person);
          diff = diff / selProds.length;

          strDiff = 'From + £' + diff.toFixed(2) + 'pp';
        } else {
          diff = 0;
          strDiff = '';
        }

        for (const bd of boards) {
          if (bd.id == bbp.bb) {
            bd.diff = diff;
            bd.diffStr = strDiff;
            break;
          }
        }
      }    
        
    
    setRoomDetailsFiltered(rgroups);
    setBoardDetails(boards);

    loadBasket();

    const jse = sessionStorage.getItem('js1');
    if(jse == null && selectedSearchParams != null){
      const adults = adultCountFromRooms(selectedSearchParams.rooms);
      const children = childCountFromRooms(selectedSearchParams.rooms);

      journeyAdd(a.id, adults, children, selectedSearchParams.rooms.length, '', 'Rooms', 1, sessionId || '', selectedSearchParams.searchType, userToken);
      sessionStorage.setItem('js1', '1');
    }


    }

    ini();


  }, []);

  const loadBasket = async () => {
    if (sessionId) {
      const basketResponse = await getBasket(userToken, sessionId);
      setBasket(basketResponse.basket.result);
      updatePricePlan(sessionId, basketResponse.basket.result.price_deposit_diff)
    }
  };

  const nextStep = async() => {
    siteNavigate(configuration, `/passengers${location.search}`, fNav);
  };

  const updatePricePlan  = (sessionId: any, basketPrice: any) => {
    paymentPayMonthly(userToken, 1, sessionId, basketPrice).then((pp) => {
      if(pp == null){
        return;
      }
      if(pp.basket_payment_plan == null){
        return;
      }
      if(pp.basket_payment_plan.result == null){
        return;
      }
      setPaymentPlan(pp.basket_payment_plan.result)
    });
  }


  if(basket == null){
    return (
      <PackageSelectLoader configuration={configuration}  />
    );
  }
  if(packageHoliday == null){
    return (
      <PackageSelectLoader configuration={configuration}  />
    );
  }

  let fadeOut = false;

  if (packageHoliday != null) {
    fadeOut = true;
    setTimeout(() => setEverythingLoaded(true), 1000);
  }

  if (!everythingLoaded) {
    return (
      <PackageSelectLoader configuration={configuration}  />
    );
  }

  ProgressBarPackage.forEach((progress) => {
    progress.isActive = false;
    progress.isCompleted = false;
  });

  ProgressBarPackage[0].isActive = true;

  let foodIco = foodDefault;
  if(configuration.icon_set == 'blue'){
    foodIco = food;
  }  
  
  let bask = null;
  let baskDesk = null;

  if(accommodation == null){
    return null;
  }


  bask = <PackageBasket basketData={basket} pricePlan={pricePlan} selectedPackage={packageHoliday} selectedRooms={selectedRooms} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={searchedRooms} />;
  baskDesk = <PackageBasket basketData={basket} pricePlan={pricePlan} selectedPackage={packageHoliday} selectedRooms={selectedRooms} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={searchedRooms}>
  <Button
    label={'Continue'}
    primary={true}
    isTransparent={false}
    onClick={() => {
      nextStep();
    }}
    backgroundColor={configuration.button_colour}
    borderColor={configuration.button_colour}
  />
</PackageBasket>


const adultCount = adultCountFromRooms(searchedRooms);
const childAges = childAgesFromRooms(searchedRooms);
let childCount = 0;
for(const a of childAges){
  if(a >= 2){
    childCount++;
  }
}
let paxCount = adultCount + childCount;

  return (
    <BaseLayout
      progressBar={ProgressBarPackage}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      isNavigationVisible={false}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='select-page-container'>
        <div className='select-page-col select-page-col-basket select-page-col-basket-mobile'>
          {bask}
        </div>
        <div className='select-page-col'>
          <div style={{borderColor:configuration.jrny.border_colour}}  className='select-page-container-select-flights'>
            <PackageFlight flightData={packageHoliday.flight}></PackageFlight>
          </div>

          <div className='select-page-divider' />

          <div style={{borderColor:configuration.jrny.border_colour}} className='board-and-rooms-container'>
            {boardDetails && (
              <div className='select-page-board-details'>
                <h4 className='board-title'>
                  <img className='board-icon' src={foodIco} height='20' width='20'></img>
                  <span className='board-title-text'>Select Board</span>
                </h4>

                <SelectBoard
                  onClick={filterBoard}
                  selectedBoadId={selectedBoardId}
                  boards={boardDetails}
                  disabled={false}
                />
              </div>
            )}

            {roomDetailsFiltered.length > 0 && (
              <PackageRoomSelection
                sessionId={sessionId}
                roomGroups={roomDetailsFiltered}
                selectedRooms={selectedRooms}
                selectedBoard={selectedBoardId}
                onSelect={(rooms) => updateSelectedRooms(rooms)}
                totalPax={paxCount}
              />
            )}
          </div>

          <div className='select-page-divider'></div>
          <div className='select-page-continue-mobile'>
            <Button
              label={'Continue'}
              primary={true}
              isTransparent={false}
              onClick={() => {
                siteNavigate(configuration, `/passengers${location.search}`, fNav);
              }}
              backgroundColor={configuration.button_colour}
              borderColor={configuration.button_colour}
            />
          </div>
        </div>

        <div className='extras-page-col extras-page-col-basket'>
          {baskDesk}
        </div>
      </div>
    </BaseLayout>
  );
};

export default observer(PackageSelectPage);
